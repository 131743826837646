import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { getWebIdentityToken } from "./MidwayAuth";
import { Loading } from "@glint/shared";

export interface IAuthState {
	alias: string;
	token: string;
	env: "prod" | "dev";
}

export const InitialAuthState: IAuthState = {
	alias: null,
	token: null,
	env: null
};

const AuthContext = createContext(InitialAuthState);

export function AuthProvider({ children }: { children: ReactNode }) {
	const authState = createAuthState();

	return <AuthContext.Provider value={authState}>{authState.alias ? children : <Loading/>}</AuthContext.Provider>;
}

function createAuthState() {
	const [alias, setAlias] = useState<string>(null);
	const [token, setToken] = useState<string>(null);
	const [env, setEnv] = useState<"prod" | "dev">(null);

	async function initAuth() {
		if (typeof __AWS__ !== "undefined") {
			setAlias(__AWS__.ALIAS);
			setEnv("dev");
		} else {
			const res = await getWebIdentityToken();
			setAlias(res.alias);
			setToken(res.token);
			setEnv("prod");
		}
	}

	useEffect(() => {
		initAuth();
	}, []);

	return {
		alias,
		token,
		env
	};
}

export function useAuthState() {
	const authState = useContext(AuthContext);

	function getAWSCreds() {
		return {
			region: "us-east-1",
			credentials:
				authState.env === "prod"
					? fromWebToken({
							roleArn: "arn:aws:iam::780910371930:role/MidwayAuthGetSmgtEvalDataRole",
							webIdentityToken: authState.token,
							clientConfig: { region: "us-east-1" }
						})
					: {
							accessKeyId: __AWS__.AWS_ACCESS_KEY_ID,
							secretAccessKey: __AWS__.AWS_SECRET_ACCESS_KEY,
							sessionToken: __AWS__.AWS_SESSION_TOKEN
						}
		};
	}

	return {
		...authState,
		getAWSCreds
	};
}
