export interface IGraph {
	name: string;
	description: string;
	graph_id: string;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
	header_links: {
		text: string;
		href: string;
		target: string;
	}[];
	nodes: { [key: string]: INode };
	edges: { [key: string]: IEdge };
}

export interface INode {
	id: string;
	name: string;
	description?: string;
	disable_metrics?: boolean;
	initial_position?: {
		x?: number;
		y?: number;
	};
	table?: string;
	sparklines?: (ILineChartConfig | IStackedAreaChartConfig)[];
	charts?: (ILineChartConfig | IStackedAreaChartConfig)[];
    kpis?: IKPI[];
}

export interface IChartConfig {
	chart_id: string;
	table: string;
    search_tables?: {
        [key: string]: string;
    };
	date_column: string;
	y_column: string;
	transform_y?: (row: any) => number;
	format_y?: (row: any) => string;
	metric_name: string;
	chart_type: ChartType;
	aggregation?: Aggregation;
}

export interface ILineChartConfig extends IChartConfig {}

export interface IStackedAreaChartConfig extends IChartConfig {
	group_by: string;
}

export enum ChartType {
	LineChart = "line_chart",
	StackedAreaChart = "stacked_area_chart"
}

export enum Aggregation {
	Sum = "sum",
	Avg = "avg"
}

export interface IKPI {
    key: string;
    date: number;
    value: number;
    format_value?: (num: number) => string;
    change?: number;
    format_change?: (num: number) => string; 
}

export interface IEdge {
	[key: string]: { tail: string };
}

export interface IFilter {
    column: string;
    values: string[];
}
