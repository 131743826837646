import { useEffect, useRef, useState } from "react";
import { useGlintState } from "@glint/state";
import { useRedshift } from "@glint/services";
import { KPI, Sparkline, StackedArea } from "@glint/charts";
import "./Drawer.scss";
import { Loading } from "@glint/shared";

export function Drawer() {
	const glintState = useGlintState();
	const rs = useRedshift();
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [drawerHeight, setDrawerHeight] = useState<number>(parseInt(localStorage.getItem("drawer-height") || `${16 * 50}`));
    const drawerRef = useRef(null);

	async function loadData() {
		if (glintState.activeNode?.charts) {
            setLoading(true)
            const result = 
				await Promise.all(
					glintState.activeNode?.charts.map(async (sp) => {
						const result = await rs.getDailyDocumentCounts(sp.table, sp.date_column, sp.y_column, sp.aggregation);
						return {
							data: result.map((d) => {
								return {
									date: new Date(d[sp.date_column]),
									y: sp.transform_y instanceof Function ? sp.transform_y(d) : d[sp.y_column]
								};
							}),
							id: sp.chart_id,
                            format_y: sp.format_y,
                            metric_name: sp.metric_name
						};
					})
				)
			setData(result);
            setLoading(false);
		} else {
			setData([]);
		}
	}

	function onMouseDown() {
		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
		document.body.style.userSelect = "none";
		document.body.style.cursor = "ns-resize";
        drawerRef.current.style.transition = "none";
	}

	function onMouseMove(e) {
		setDrawerHeight((h) => {
            const new_height = h - e.movementY;
            localStorage.setItem("drawer-height", new_height.toString())
            return new_height
        });
	}

	function onMouseUp() {
		document.removeEventListener("mousemove", onMouseMove);
		document.removeEventListener("mouseup", onMouseUp);
		document.body.style.userSelect = "";
		document.body.style.cursor = "";
        drawerRef.current.style.transition = "";
	}

	useEffect(() => {
		loadData();
	}, [glintState.activeNode]);

	return (
		<div className={`__glint-drawer-container ${glintState.activeNode ? "open" : "closed"}`} ref={drawerRef} style={{height: `${drawerHeight}px`}}>
			<div
				className="drawer-handle"
				onMouseDown={onMouseDown}
			>
                <div className="drawer-thumb">
                    <div/><div/>
                </div>
            </div>
			<div className="charts">
				{loading ? <div className="loading"><Loading/></div> : data.map((d) => (
					<StackedArea
                        title={d.metric_name}
						key={d.id}
						data={d.data}
                        format_y={d.format_y}
					/>
				))}
			</div>
			<div className="details">
				<div className="details-inner">
					<div className="details-name">{glintState.activeNode?.name}</div>
                    <div className="details-kpis">
                        {
                            glintState.activeNode?.kpis?.map(kpi => <KPI key={kpi.key} kpi={kpi}/>)
                        }
                    </div>
                    <div className="details-sparklines">
                        {loading ? <Loading/> : data.map((d) => (
                            <div key={d.id} className="details-sparkline">
                                <div className="sparkline-name">{d.metric_name}</div>
                                <Sparkline
                                    data={d.data}
                                    format_y={d.format_y}
                                />
                            </div>
                        ))}
                    </div>
				</div>
			</div>
		</div>
	);
}
