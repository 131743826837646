import { useRef } from "react";

export function useDebounce(cb: (e: any) => any, timeout: number): (e: any) => void {
    const ref = useRef(null);

    function update(e: any) {
        const id = Math.random();
       ref.current = id;

       setTimeout(() => {
            if(id === ref.current) {
                cb(e);
            }
       }, timeout)
    }

    return update;
}
