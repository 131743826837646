import React, { useState } from "react";
import "./Toolbar.scss";
import { Button, ButtonVariant, Input } from "@glint/shared";
import {  } from "../Shared/Input/Input";
import { useGlintState } from "@glint/state";

export function Toolbar() {
    const glintState = useGlintState();
    const [open, setOpen] = useState<boolean>(false);
    const [domainFilter, setDomainFilter] = useState<string>("")
    
    function searchDomains(){
        glintState.setActiveFilters([{column: "domain", values: [domainFilter]}])
    }

    return (
        <>
            <div className="__glint-toolbar-toggle">
                <Button icon={"/menu-icon.svg"} variant={ButtonVariant.Icon} onClick={() => setOpen(true)}>
                </Button>
            </div>
            <div className={`__glint-toolbar-container ${open ? "open" : "closed"}`}>
                <div className="close-toolbar">
                    <Button icon={"/arrow-right-icon.svg"} variant={ButtonVariant.Icon} onClick={() => setOpen(false)}>
                    </Button>
                </div>
                <div className="inner">
                    Domain Filter
                    <Input value={domainFilter} onChange={(e) => setDomainFilter(e)}/>
                    <Button onClick={searchDomains}>Submit</Button>
                </div>
            </div>
        </>
    )
}
