export function toTrillions(num: number): string {
    const value = Math.round(num / 10000000000) / 100;
    if(value < 1) {
        return toBillions(num)
    }
    return `${value} T`
}

export function toBillions(num: number): string {
    const value = Math.round(num / 10000000) / 100;
    if(value < 1) {
        return toMillions(num)
    }
    return `${value} B`
}

export function toMillions(num: number): string {
    const value = Math.round(num / 10000) / 100;
    if(value < 1) {
        return toThousands(num)
    }
    return `${value} M`
}

export function toThousands(num: number): string {
    const value = Math.round(num / 10) / 100;
    if(value < 1) {
        return num.toString();
    }
    return `${value} K`
}

export function toPercent(num: number): string {
    return `${Math.round(num * 100 * 100) / 100}%`
}
