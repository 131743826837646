import { IEdge } from "@glint/types";
import { useGlintState } from "@glint/state";
import "./Edge.scss";

export function Edge({ edge, id }: { edge: IEdge; id: string }) {
	const glintState = useGlintState();

	return (
		<div
			className="__glint-edge-container"
			style={{ top: `${glintState.y}px`, left: `${glintState.x}px` }}
		>
			<svg
				width={document.body.clientWidth}
				height={document.body.clientHeight}
			>
				{Object.keys(edge).map((key) => (
					<Line
						key={key}
						from={id}
						to={edge[key].tail}
					/>
				))}
			</svg>
		</div>
	);
}

function Line({ from, to }: { from: string; to: string }) {
	const glintState = useGlintState();

	function hidden() {
		if (glintState.activeNode) {
			if (
				glintState.edgeDeps[glintState.activeNode.id].includes(to) &&
				glintState.edgeDeps[glintState.activeNode.id].includes(from)
			) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	}

    function disabled() {
        glintState.graph.nodes[from].disable_metrics
    }

	if (glintState.getNodePosition(from) && glintState.getNodePosition(to)) {
		return (
			<line
				className={`__glint-edge-line-container ${hidden() ? "hidden" : ""}`}
				x1={glintState.getNodePosition(from)?.x}
				y1={glintState.getNodePosition(from)?.y + (glintState.graph.nodes[from].disable_metrics ? 1.5 : 3.5) * 16}
				x2={glintState.getNodePosition(to)?.x + 8.25 * 16}
				y2={glintState.getNodePosition(to)?.y + (glintState.graph.nodes[to].disable_metrics ? 1.5 : 3.5) * 16}
				stroke="black"
				strokeDasharray="4"
			/>
		);
	}
}
