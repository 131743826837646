import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultGraph from "@glint/configuration/default";
import { App } from "./App";

export function Router() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path={""}
					element={<App graph={DefaultGraph} />}
				/>
			</Routes>
		</BrowserRouter>
	);
}
