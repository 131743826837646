function buildMidwayRequest(redirect = false) {
	const redirect_uri = `https://${window.location.host}`;

	const params = {
		scope: "openid",
		response_type: "id_token",
		client_id: redirect_uri,
		redirect_uri,
		nonce: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
	};

	if (!redirect) {
		params.sentry_handler_version = "MidwayNginxModule-1.3-1";
	}

	const queryString = buildQueryString(params);

	if (redirect) {
		return `https://midway-auth.amazon.com/SSO/redirect?${queryString}`;
	}
	return `https://midway-auth.amazon.com/SSO?${queryString}`;
}

function buildQueryString(params) {
	const paramsArray = [];

	for (const key of Object.keys(params)) {
		paramsArray.push(`${key}=${params[key]}`);
	}

	return paramsArray.join("&");
}

export async function getWebIdentityToken() {
	const res = await fetch(buildMidwayRequest(false), { credentials: "include" });
	if (res.ok) {
		const token = await res.text();
		const alias = parseJwt(token).sub;
		return { token, alias };
	} else {
		window.location.href = buildMidwayRequest(true);
	}
}

function parseJwt(token: string) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);

	return JSON.parse(jsonPayload);
}
