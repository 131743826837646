import React from "react";
import type { IGraph } from "@glint/types";
import { AuthProvider, GlintProvider } from "@glint/state";
import { Canvas } from "./Components/Canvas";
import { Drawer } from "./Components/Drawer";
import { Header } from "./Components/Header";
import { Toolbar } from "./Components/Toolbar";
import { Filters } from "./Components/Filters";

export function App({ graph }: { graph: IGraph }) {
	return (
		<AuthProvider>
			<GlintProvider defaultGraph={graph}>
				<Header />
                <Filters />
				<Canvas />
                <Toolbar/>
				<Drawer />
			</GlintProvider>
		</AuthProvider>
	);
}
