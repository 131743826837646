import { Button } from "@glint/shared";
import { useGlintState } from "@glint/state";
import React from "react";

import "./Filters.scss";

export function Filters() {
    const glintState = useGlintState();

    return (
        <div className={"__glint-filters-container"}>
            {
                glintState.activeFilters?.map(filter => (
                    <Button key={filter.column} onClick={() => glintState.setActiveFilters([])} icon="/close-icon.svg">{filter.column} = {filter.values[0]}</Button>
                ))
            }                       
        </div>
    )
}
