import React, { type ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Link.scss";


export interface ILinkProps {
	children: ReactNode;
	href: string;
	target: string;
}

export function Link({ children, href, target }: ILinkProps) {
	return (
		<RouterLink
			to={href}
			target={target}
			className="__glint-link"
		>
			{children}
		</RouterLink>
	);
}
