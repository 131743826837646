import React from "react";
import "./Input.scss";

export interface IInputProps {
    value: string;
    onChange: (e: string) => void;
    placeholder?: string;
}

export function Input({value, onChange, placeholder}: IInputProps){
    
    return (
        <input className="__glint-input" value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder}/>
    )
}
