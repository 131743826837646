import React from "react";
import { useAuthState, useGlintState } from "@glint/state";
import "./Header.scss";
import { Link } from "@glint/shared";

export function Header() {
	const authState = useAuthState();
	const { graph } = useGlintState();

	return (
		<div className="__glint-header-container">
			<div className="title">Glint / {authState.alias}</div>
			<div className="contact">{graph.header_links?.map((link) => <Link key={link.href} href={link.href} target={link.target}>{link.text}</Link>)}</div>
		</div>
	);
}
