import { Aggregation, ChartType, type IGraph } from "@glint/types";
import { toTrillions, toPercent } from "@glint/utils";

const graph: IGraph = {
	name: "Glint Default Graph",
	description: "Glint Graph with example data",
	graph_id: "123LUQ",
	lastModifiedTimestamp: new Date().getTime(),
	createdTimestamp: new Date().getTime(),
	header_links: [
		{
			text: "docs",
			href: "https://amazon.com/#docs",
			target: "_blank"
		},
		{
			text: "contact",
			href: "https://amazon.com/#contact",
			target: "_blank"
		},
		{
			text: "requests",
			href: "https://amazon.com/#requests",
			target: "_blank"
		}
	],
	nodes: {
		amazon_bot: {
			id: "amazon_bot",
			name: "Amazon Bot",
            disable_metrics: true,
			initial_position: {
				x: 0.05,
				y: 0.4
			}
		},
		crawled_images: {
			id: "crawled_images",
			name: "Crawled Images",
			initial_position: {
				x: 0.22,
				y: 0.4
			}
		},
		crawled_web_pages: {
			id: "crawled_web_pages",
			name: "Crawled Web Pages",
			initial_position: {
				x: 0.22,
				y: 0.12
			},
			table: "glint_ds1vx",
			sparklines: [
				{
					chart_id: "ds1vx-sparkline",
					table: "glint_ds1vx",
                    search_tables: {
                        domain: "glint_ds1vx_domains_shallow"
                    },
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
			charts: [
				{
					chart_id: "ds1vx-document-count",
					table: "glint_ds1vx",
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
            kpis: [
                {
                    key: "Attempted to Crawl",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 30000000000,
                    change: 0.01,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Failed to Crawl",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 1700000000,
                    change: -0.02,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Yield",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.8237,
                    change: 0.0018,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Recall @ Infinity",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.7217,
                    change: 0.002,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                }
            ]
		},
		crawled_videos: {
			id: "crawled_videos",
			name: "Crawled Videos",
			initial_position: {
				x: 0.22,
				y: 0.7
			},
			sparklines: [
				{
					chart_id: "ds11vx-sparkline",
					table: "glint_ds11vx",
                    search_tables: {
                        domain: "glint_ds11vx_domains_shallow"
                    },
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			]
		},
		luigi2_evergreen_input: {
			id: "luigi2_evergreen_input",
			name: "Luigi2 Input",
			initial_position: {
				x: 0.36,
				y: 0.12
			},
			sparklines: [
				{
					chart_id: "ds2vx-sparkline",
					table: "glint_ds2vx",
                    search_tables: {
                        domain: "glint_ds2vx_domains_shallow"
                    },
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
			charts: [
				{
					chart_id: "ds2vx-document-count",
					table: "glint_ds2vx",
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
            kpis: [
                {
                    key: "Documents Delivered",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 30000000000,
                    change: 0.1,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Yield",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.8237,
                    change: 0.0018,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Recall @ Infinity",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.6622,
                    change: 0.002,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                }
            ]
		},
		luigi2_evergreen: {
			id: "luigi2_evergreen",
			name: "Luigi2 Evergreen",
			initial_position: {
				x: 0.5,
				y: 0.12
			},
			table: "glint_ds3vx",
			sparklines: [
				{
					chart_id: "ds3vx-sparkline",
					table: "glint_ds3vx",
                    search_tables: {
                        domain: "glint_ds3vx_domains_shallow"
                    },
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
			charts: [
				{
					chart_id: "ds3vx-document-count",
					table: "glint_ds3vx",
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Output Documents",
					chart_type: ChartType.LineChart
				},
                {
					chart_id: "ds2vx-document-count",
					table: "glint_ds2vx",
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Input Documents",
					chart_type: ChartType.LineChart
				}
			],
            kpis: [
                {
                    key: "Output Documents",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 6000000000,
                    change: 0.04,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Documents Dropped",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 3000000000,
                    change: 0.08,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Yield",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.5237,
                    change: -0.08,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Recall @ Infinity",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.6212,
                    change: 0.002,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                }
            ]
		},
		mmc_image_processing: {
			id: "mmc_image_processing",
			name: "MMC Images",
			initial_position: {
				x: 0.42,
				y: 0.4
			}
		},
		mmc_video_processing: {
			id: "mmc_video_processing",
			name: "MMC Video",
			initial_position: {
				x: 0.42,
				y: 0.7
			}
		},
		qweps_content_selection: {
			id: "qweps_content_selection",
			name: "QWePs Content Selection",
			initial_position: {
				x: 0.65,
				y: 0.3
			}
		},
		qweps_snapshot: {
			id: "qweps_snapshot",
			name: "QWePs Snapshot",
			initial_position: {
				x: 0.82,
				y: 0.4
			}
		},
		llm_data_drops: {
			id: "llm_data_drops",
			name: "LLM Data Drops",
			description: "The LLM Data Drops pipeline releases training data to Olympus from the Luigi pipeline.",
			initial_position: {
				x: 0.82,
				y: 0.12
			},
			table: "glint_ds4vx",
			sparklines: [
				{
					chart_id: "ds4vx-sparkline",
					table: "glint_ds4vx",
                    search_tables: {
                        domain: "glint_ds4vx_domains_shallow"
                    },
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				}
			],
			charts: [
				{
					chart_id: "ds4vx-document-count",
					table: "glint_ds4vx",
					date_column: "acquired",
					y_column: "documents",
                    format_y: toTrillions,
					metric_name: "Documents",
					chart_type: ChartType.LineChart
				},
				{
					chart_id: "ds4vx-token-count",
					table: "glint_ds4vx",
					date_column: "acquired",
					y_column: "chars",
					transform_y(row) {
						return row.chars / 4;
					},
                    format_y: toTrillions,
					metric_name: "Tokens",
					chart_type: ChartType.LineChart,
					aggregation: Aggregation.Avg
				}
			],
            kpis: [
                {
                    key: "Documents Delivered",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 2000000000,
                    change: 0.05,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Tokens Delivered",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 1700000000000,
                    change: 0.07,
                    format_value: toTrillions,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                },
                {
                    key: "Yield",
                    date: new Date().getTime() - (3600 * 24 * 18 * 1000),
                    value: 0.9998,
                    change: 0.0001,
                    format_value: toPercent,
                    format_change(num: number){
                        const sign = num > 0 ? "+" : num < 0 ? "" : "";
                        return `${sign}${toPercent(num)}`;
                    }
                }
            ]
		},
		orca_text: {
			id: "orca_text",
			name: "Orca Text Store",
			initial_position: {
				x: 0.82,
				y: 0.26
			}
		},
		orca_images: {
			id: "orca_images",
			name: "Orca Image Store",
			initial_position: {
				x: 0.82,
				y: 0.55
			}
		},
		orca_video: {
			id: "orca_video",
			name: "Orca Video Store",
			initial_position: {
				x: 0.82,
				y: 0.7
			}
		}
	},
	edges: {
		amazon_bot: {},
		crawled_web_pages: {
			amazon_bot: { tail: "amazon_bot" }
		},
		crawled_images: {
			amazon_bot: { tail: "amazon_bot" }
		},
		crawled_videos: {
			amazon_bot: { tail: "amazon_bot" }
		},
		luigi2_evergreen_input: {
			amazon_bot: { tail: "crawled_web_pages" }
		},
		luigi2_evergreen: {
			amazon_bot: { tail: "luigi2_evergreen_input" }
		},
		llm_data_drops: {
			luigi2: { tail: "luigi2_evergreen" }
		},
		mmc_video_processing: {
			crawled_videos: { tail: "crawled_videos" }
		},
		mmc_image_processing: {
			crawled_images: { tail: "crawled_images" }
		},
		qweps_content_selection: {
			luigi2: { tail: "luigi2_evergreen" },
			images: { tail: "mmc_image_processing" }
		},
		qweps_snapshot: {
			qweps_pipeline: { tail: "qweps_content_selection" }
		},
		orca_images: {
			mmc: { tail: "mmc_image_processing" }
		},
		orca_video: {
			mmc: { tail: "mmc_video_processing" }
		},
		orca_text: {
			luigi2: { tail: "luigi2_evergreen" }
		}
	}
};

export default graph;
