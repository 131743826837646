import { useEffect } from "react";
import { useGlintState } from "@glint/state";
import { useRedshift } from "../../Services/Redshift/Redshift";
import { Edge } from "../Edge";
import { Node } from "../Node";
import "./Graph.scss";

export function Graph() {
	const { graph } = useGlintState();
	const rs = useRedshift();

	async function query() {
		const result = await rs.getDailyDocumentCounts("glint_ds3vx");
	}

	useEffect(() => {
		query();
	}, [rs]);

	return (
		<div className="__glint-graph-container">
			{Object.keys(graph.nodes).map((key) => (
				<Node
					key={key}
					node={graph.nodes[key]}
				/>
			))}
			{Object.keys(graph.edges).map((key) => (
				<Edge
					key={key}
					id={key}
					edge={graph.edges[key]}
				/>
			))}
		</div>
	);
}
