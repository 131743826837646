import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { INode } from "@glint/types";
import { useGlintState } from "@glint/state";
import { useRedshift } from "@glint/services";
import { Sparkline } from "@glint/charts";
import "./Node.scss";
import { Loading } from "@glint/shared";

export function Node({ node }: { node: INode }) {
	const glintState = useGlintState();
	const rs = useRedshift();
	const [params, setParams] = useSearchParams();

	const [x, setX] = useState<number>(0);
	const [y, setY] = useState<number>(0);
	const [active, setActive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState([]);

	const activeRef = useRef(null);

	async function loadData() {
		if (node.sparklines) {
            setLoading(true)
            const result = await Promise.all(
					node.sparklines.map(async (sp) => {
                        let table = sp.table;
                        let where = null;

                        if(glintState.activeFilters?.length) {
                            table = sp.search_tables[glintState.activeFilters[0].column];
                            where = `where ${glintState.activeFilters[0].column} = '${glintState.activeFilters[0].values[0]}'`
                        }
						const result = await rs.getDailyDocumentCounts(table, sp.date_column, sp.y_column, sp.aggregation, where);
						return {
							data: result.map((d) => {
								return {
									date: new Date(d[sp.date_column]),
									y: sp.transform_y instanceof Function ? sp.transform_y(d) : d[sp.y_column]
								};
							}),
							id: `${sp.chart_id}-${Math.random()}`,
                            format_y: sp.format_y
						};
					})
				)
			setData(
				result
			);
            setLoading(false);
		} else {
			setData([
				{
					data: [
						{ date: new Date().getTime() - 3600, y: 0 },
						{ date: new Date().getTime(), y: 0 }
					],
					id: "placeholder"
				}
			]);
		}
	}

	function hidden() {
		if (glintState.activeNode) {
			if (glintState.edgeDeps[glintState.activeNode.id].includes(node.id)) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	}

	function onMouseDown() {
		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
		document.body.style.cursor = "grabbing";
		document.body.style.userSelect = "none";
	}

	function onMouseMove(e) {
		setX((cur_x) => cur_x + e.movementX / glintState.scale);
		setY((cur_y) => cur_y + e.movementY / glintState.scale);
		setActive(true);
		activeRef.current = true;
	}

	function onMouseUp() {
		document.removeEventListener("mousemove", onMouseMove);
		document.removeEventListener("mouseup", onMouseUp);
		document.body.style.cursor = "";
		document.body.style.userSelect = "";
		setActive(false);
		if (!activeRef.current) {
			activateNode();
		} else {
			activeRef.current = false;
		}
	}

	function activateNode() {
        if(node.disable_metrics !== true) {
            glintState.setActiveNode((a) => {
                if (a?.id !== node.id) {
                    setParams({ a: node.id });
                    return node;
                }
                setParams({});
                return null;
            });
        }
	}

	useEffect(() => {
		loadData();
	}, [glintState.activeFilters]);

	useEffect(() => {
		if (node && glintState.reportNodePosition) {
			glintState.reportNodePosition(node.id, x, y);
		}
	}, [node, x, y]);

	useEffect(() => {
		if (node.initial_position?.x) {
			setX(document.body.clientWidth * node.initial_position.x);
		}
		if (node.initial_position?.y) {
			setY(document.body.clientHeight * node.initial_position.y);
		}
	}, [node]);

	return (
		<div
			className={`__glint-node-container ${hidden() ? "hidden" : ""} ${node.disable_metrics === true ? "disabled" : ""}`}
			onMouseDown={onMouseDown}
			style={{ left: `${x + glintState.x}px`, top: `${y + glintState.y}px` }}
		>
			<div className={`node-inner ${glintState.activeNode?.id === node.id ? "active" : ""}`}>
				<div className="node-name">{node.name}</div>
				{node.disable_metrics !== true && (loading ? <div className="loading"><Loading/></div> : data.map((d) => (
					<Sparkline
						key={d.id}
						data={d.data}
                        format_y={d.format_y}
					/>
				)))}
			</div>
		</div>
	);
}
