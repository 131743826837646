import React, { ReactNode } from "react";
import "./Button.scss";

export interface IButtonProps {
    children: ReactNode;
    icon?: string;
    onClick: () => void;
    variant: ButtonVariant;
}

export enum ButtonVariant {
    Primary = "primary",
    Secondary = "secondary",
    Icon = "icon"
}

export function Button({children, icon, onClick, variant}: IButtonProps) {
    return (
        <button className={`__glint-button ${variant}`} onClick={onClick}>
           {children}
           {icon && (
                <img src={icon} width={18}/>
           )}
        </button>
    )
}
