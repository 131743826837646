import React from "react";
import "./KPI.scss";
import { IKPI } from "@glint/types";


export function KPI({kpi}: {kpi: IKPI}) {
    return (
        <div className="__glint-kpi-container">
            <div className="key">
                {kpi.key}
            </div>
            <div className="value">
                {kpi.format_value ? kpi.format_value(kpi.value) : kpi.value}
            </div>
            <div className="kpi-details">
                <div className={`change ${kpi.change > 0 ? "positive" : kpi.change < 0 ? "negative" : "neutral"}`}>
                    {kpi.format_change ? kpi.format_change(kpi.change) : kpi.change}
                </div>
                <div className="date">
                    {new Date(kpi.date).toLocaleDateString("en-US")}
                </div>
            </div>
        </div>
    )
}
