export function animate(from: number, to: number, duration: number, cb: (e: number) => void) {
	const fps = 120;
	const fpms = fps / 1000;
	const n_steps = Math.floor(fpms * duration);
	const distance = to - from;
	const step_size = distance / n_steps;
	const step_duration = duration / n_steps;

	let idx = 0;
	let interval;

	function next() {
		idx++;

		cb(from + step_size * idx);

		if (idx > n_steps) {
			cb(to);
			clearInterval(interval);
		}
	}
	interval = setInterval(next, step_duration);
}
