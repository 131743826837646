import React from "react";
import "./Loading.scss";

export function Loading() {
    return (
        <div className="__glint-loading">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    )
}
