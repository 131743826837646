import { useGlintState } from "@glint/state";
import { Graph } from "../Graph";
import "./Canvas.scss";
import { useRef } from "react";

export function Canvas() {
	const glintState = useGlintState();
    const canvasRef = useRef(null);

	function onMouseDown() {
		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
		canvasRef.current.style.cursor = "grabbing";
		document.body.style.userSelect = "none";
	}

	function onMouseMove(e) {
		glintState.setX((x) => x + e.movementX);
		glintState.setY((y) => y + e.movementY);
	}

	function onMouseUp() {
		document.removeEventListener("mousemove", onMouseMove);
		document.removeEventListener("mouseup", onMouseUp);
		canvasRef.current.style.cursor = "";
		document.body.style.userSelect = "";
	}

	return (
		<div className="__glint-canvas-container">
			<Graph />
            <img className="move-indicator" src={"/move-any-direction.svg"}/>
			<div
				className="bg"
				onMouseDown={onMouseDown}
                ref={canvasRef}
			/>
		</div>
	);
}
