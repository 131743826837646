import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./Sparkline.scss"
import { useGlintState } from "@glint/state";
import { useDebounce } from "@glint/utils";


export interface ISparklineData {
	date: number;
	y: number;
}

const ChartGreen = "#67a353";
const ChartRed = "#ea7158";
const ChartBlue = "#4682B4";
const ChartGray = "#d6d6d6";

export function Sparkline({ data, format_y }: { data: ISparklineData[]; format_y?: (e: number) => string;}) {
    const glintState = useGlintState();
    const [currentDate, setCurrentDate] = useState<string>(null);
    const [currentY, setCurrentY] = useState<string>(null);
	const ref = useRef(null);
	ref.current = null;
	const mounted = useRef(null);
	mounted.current = false;

    const circleRef = useRef(null);
    const verticalRef = useRef(null);
    const xRef = useRef(null);
    const yRef = useRef(null);

    const debounceTooltip = useDebounce(glintState.setSelectedDate, 5)

	useEffect(() => {
		if (ref.current && !mounted.current && data) {
			mounted.current = true;
			const svg = d3.select(ref.current).append("g");

			const marginTop = 4;
			const marginRight = 4;
			const marginBottom = 4;
			const marginLeft = 4;
			const width = 7 * 16;
			const height = 2 * 16;

			// Declare the y (vertical position) scale.
			const y = d3.scaleLinear([0, d3.max(data, (d) => d.y)], [height - marginBottom, marginTop]);
            yRef.current = y;
			const x = d3.scaleLinear([d3.min(data, (d) => d.date), d3.max(data, (d) => d.date)], [marginLeft, width]);
            xRef.current = x;

			// Declare the line generator.
			const line = d3
				.line()
				.x((d) => x(d.date))
				.y((d) => y(d.y))
				.curve(d3.curveCatmullRom.alpha(0.5));
			const change = data.length > 1 ? data[data.length - 1].y - data[0].y : 0;
			
            const path = svg
				.append("path")
				.attr("fill", "none")
				.attr("stroke", change > 0 ? ChartGreen : change < 0 ? ChartRed : ChartGray)
				.attr("stroke-width", 1.5)
				.attr("d", line(data));

			const length = path.node().getTotalLength();
			path.attr("stroke-dasharray", `${length} ${length}`)
				.attr("stroke-dashoffset", length)
				.transition()
				.ease(d3.easeLinear)
				.attr("stroke-dashoffset", 0)
				.delay(600 * Math.random())
				.duration(900);

            const circle = svg.append("circle")
                    .attr("r", 0)
                    .style("fill", change > 0 ? ChartGreen : change < 0 ? ChartRed : ChartGray)
                    .style("pointer-events", "none")
                    .attr("opacity", .7);

            circleRef.current = circle;

            const verticalLine = svg.append("rect")
                .style("fill", "rgba(0, 0, 0, 0.4)")
                .style("pointer-events", "none")
                .attr("width", 1)
                .attr("height", 2 * 16)
                .attr("opacity", 0);

            verticalRef.current = verticalLine;

            const bgRect = svg.append("rect")
                .attr("height", 2 * 16)
                .attr("width", 8 * 16)
                .attr("fill", "transparent");
            

            const lastY = data[data.length - 1].y;
            const lastDate = data[data.length - 1].date;

            setCurrentY(format_y ? format_y(lastY) : `${lastY}`)
            setCurrentDate(new Date(lastDate).toLocaleDateString("en-US"))

            bgRect.on("mousemove", function(event){
                const [xCoord] = d3.pointer(event, this);
                const bisectDate = d3.bisector(d => d.date).left;
                const x0 = x.invert(xCoord);
                const i = bisectDate(data, x0, 1);
                const d0 = data[i - 1];
                const d1 = data[i];
                const d = (x0 - d0?.date) > (d1?.date - x0) ? d1 : d0;
                debounceTooltip(d.date);
                //(d.date);
                const xPos = x(d.date);
                const yPos = y(d.y);

                circle.attr("cx", xPos);
                circle.attr("cy", yPos);
                circle.transition().duration(50).attr("r", 5);

                const text = format_y ? format_y(d.y) : `${d.y}`;
                setCurrentY(text)
                setCurrentDate(new Date(d.date).toLocaleDateString("en-US"))

                verticalLine.attr("x", xPos);
                verticalLine.transition().duration(50).attr("opacity", 0.7);
            })
		}
	}, [data]);

    useEffect(() => {
        if(glintState.selectedDate){
            const dates = data.map((d, idx) => ({date: Math.abs(new Date(glintState.selectedDate).getTime() - d.date), idx}));
            const dp = data[dates.sort((a, b) => a.date - b.date)[0].idx];

            if(dp) {
                const xPos = xRef.current(dp.date);
                const yPos = yRef.current(dp.y);

                circleRef.current.attr("cx", xPos);
                circleRef.current.attr("cy", yPos);
                circleRef.current.transition().duration(50).attr("r", 5);

                verticalRef.current.attr("x", xPos);
                verticalRef.current.transition().duration(50).attr("opacity", 0.7);

                const text = format_y ? format_y(dp.y) : `${dp.y}`;
                setCurrentY(text)
                setCurrentDate(new Date(dp.date).toLocaleDateString("en-US"))
            }
        }
    }, [glintState.selectedDate])

	return (
        <>
		<svg
			width={8 * 16}
			height={2 * 16}
			ref={ref}
		/>
        <div className="__glint-sparkline-details">
            <div className="value">
                {currentDate}
            </div>
            <div className="value">
                {currentY}
            </div>
        </div>
        </>
	);
}
