import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./StackedArea.scss";

export interface IStackedAreaData {
	date: Date;
	category: string;
	y: number;
}

export function StackedArea({ data, format_y, title }: { data: IStackedAreaData[]; format_y?: (num: number) => string; title?: string; }) {
   const [currentDate, setCurrentDate] = useState<string>(null);
    const [currentY, setCurrentY] = useState<string>(null);
	const ref = useRef(null);
	const containerRef = useRef(null);
	const mountedRef = useRef(null);

	useEffect(() => {
		if (ref.current && !mountedRef.current && containerRef.current) {
			mountedRef.current = true;
			const width = containerRef.current.clientWidth - 32;
			const height = 500;
			const marginTop = 10;
			const marginRight = 20;
			const marginBottom = 20;
			const marginLeft = 40;

			// Determine the series that need to be stacked.
			const series = d3
				.stack()
				.keys(d3.union(data.map((d) => d.category))) // distinct series keys, in input order
				.value(([, D], key) => D.get(key).y)(
				// get value for each series key and stack
				d3.index(
					data,
					(d) => d.date,
					(d) => d.category
				)
			); // group by stack then series key

			// Prepare the scales for positional and color encodings.
			const x = d3
				.scaleUtc()
				.domain(d3.extent(data, (d) => d.date))
				.range([marginLeft, width - marginRight]);

			const y = d3
				.scaleLinear()
				.domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
				.rangeRound([height - marginBottom, marginTop]);

			const color = d3
				.scaleOrdinal()
				.domain(series.map((d) => d.key))
				.range(d3.schemeTableau10);

			// Construct an area shape.
			const area = d3
				.area()
				.x((d) => x(d.data[0]))
				.y0((d) => y(d[0]))
				.y1((d) => y(d[1]));

			// Create the SVG container.
			const svg = d3
				.select(ref.current)
				.attr("width", width)
				.attr("height", height)
				.attr("viewBox", [0, 0, width, height])
				.attr("style", "max-width: 100%; height: auto;");

			// Add the y-axis, remove the domain line, add grid lines and a label.
			svg.append("g")
				.attr("transform", `translate(${marginLeft},0)`)
				.call(d3.axisLeft(y).ticks(height / 80).tickFormat(d => d === 0 ? 0 : format_y ? format_y(d) : d))
				.call((g) => g.select(".domain").remove())
				.call((g) =>
					g
						.selectAll(".tick line")
						.clone()
						.attr("x2", width - marginLeft - marginRight)
						.attr("stroke-opacity", 0.1)
				);

			// Append a path for each series.
			svg.append("g")
				.selectAll()
				.data(series)
				.join("path")
				.attr("fill", (d) => color(d.key))
				.attr("d", area)
				.append("title")
				.text((d) => d.key);

			// Append the horizontal axis atop the area.
			svg.append("g")
				.attr("transform", `translate(0,${height - marginBottom})`)
				.call(d3.axisBottom(x).tickSizeOuter(0));
            
            
            const circle = svg.append("circle")
                    .attr("r", 0)
                    .style("fill", "steelblue")
                    .style("pointer-events", "none")
                    .attr("opacity", .7);

            const verticalLine = svg.append("rect")
                .style("fill", "rgba(0, 0, 0, 0.4)")
                .style("pointer-events", "none")
                .attr("width", 1)
                .attr("height", "100%")
                .attr("opacity", 0);

            const horizontalLine = svg.append("rect")
                .style("fill", "rgba(0, 0, 0, 0.4)")
                .style("pointer-events", "none")
                .attr("height", 1)
                .attr("width", "100%")
                .attr("opacity", 0);

            const tooltipText = svg.append("text")
                .style("font-size", "0.65rem")
                .style("font-weight", "600")


            const bgRect = svg.append("rect")
                .attr("height", "100%")
                .attr("width", "100%")
                .attr("fill", "transparent");

            
            const d = data[data.length - 1];

            const text = format_y ? format_y(d.y) : `${d.y}`;
            setCurrentDate(new Date(d.date).toLocaleDateString("en-US"));
            setCurrentY(text)

            bgRect.on("mousemove", function(event){
                const [xCoord] = d3.pointer(event, this);
                const bisectDate = d3.bisector(d => d.date).left;
                const x0 = x.invert(xCoord);
                const i = bisectDate(data, x0, 1);
                const d0 = data[i - 1];
                const d1 = data[i];
                const d = (x0 - d0.date) > (d1.date - x0) ? d1 : d0;
                const xPos = x(d.date);
                const yPos = y(d.y);
                
                circle.attr("cx", xPos);
                circle.attr("cy", yPos);
                circle.transition().duration(50).attr("r", 5);

                verticalLine.attr("x", xPos)
                verticalLine.transition().duration(50).attr("opacity", 1);

                horizontalLine.attr("y", yPos)
                horizontalLine.transition().duration(50).attr("opacity", 1);
                tooltipText.attr("y", yPos - 2);

                const text = format_y ? format_y(d.y) : `${d.y}`;
                tooltipText.text(text)
                setCurrentDate(new Date(d.date).toLocaleDateString("en-US"));
                setCurrentY(text)
            })
		}
	}, [data]);

	return (
		<div
			className="__glint-stacked-area-container"
			ref={containerRef}
		>
            {title && <div className={"title"}>{title}</div>}
            <div className="data-details">
                <div className="value">
                    {currentDate}
                </div>
                <div className="value">
                    {currentY}
                </div>
            </div>
			<svg
				width={600}
				height={500}
				ref={ref}
			/>
		</div>
	);
}
