import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { IFilter, IGraph, INode } from "@glint/types";
import { animate } from "@glint/utils";

interface IGlintState {
	graph: IGraph;
	setGraph: Dispatch<SetStateAction<IGraph>>;
	x: number;
	setX: Dispatch<SetStateAction<number>>;
	y: number;
	setY: Dispatch<SetStateAction<number>>;
	scale: number;
	setScale: Dispatch<SetStateAction<number>>;
	nodePositions: { [key: string]: { x: number; y: number } };
	setNodePositions: Dispatch<SetStateAction<{ [key: string]: { x: number; y: number } }>>;
	edgeDeps: { [key: string]: string[] };
	setEdgeDeps: Dispatch<SetStateAction<{ [key: string]: string[] }>>;
	activeNode: INode;
	setActiveNode: Dispatch<SetStateAction<INode>>;
    activeFilters: IFilter[];
    setActiveFilters: Dispatch<SetStateAction<IFilter[]>>;
    selectedDate: any;
    setSelectedDate: Dispatch<SetStateAction<any>>;
}

const InitialGlintState: IGlintState = {
	graph: null,
	setGraph: null,
	x: null,
	setX: null,
	y: null,
	setY: null,
	scale: null,
	setScale: null,
	nodePositions: null,
	setNodePositions: null,
	edgeDeps: null,
	setEdgeDeps: null,
	activeNode: null,
	setActiveNode: null,
    activeFilters: null,
    setActiveFilters: null,
    selectedDate: null,
    setSelectedDate: null
};

const GlintContext = createContext(InitialGlintState);

export function GlintProvider({ children, defaultGraph }: { children: ReactNode; defaultGraph: IGraph }) {
	const glintState = createGlintState(defaultGraph);

	return <GlintContext.Provider value={glintState}>{children}</GlintContext.Provider>;
}

function createGlintState(defaultGraph: IGraph): IGlintState {
	const [graph, setGraph] = useState<IGraph>(defaultGraph);
	const [x, setX] = useState<number>(0);
	const [y, setY] = useState<number>(0);
	const [scale, setScale] = useState<number>(1);
	const [nodePositions, setNodePositions] = useState({});
	const [edgeDeps, setEdgeDeps] = useState<{ [key: string]: string[] }>({});
	const [activeNode, setActiveNode] = useState<INode>(null);
    const [activeFilters, setActiveFilters] = useState<IFilter[]>();
    const [selectedDate, setSelectedDate] = useState(null);
	const prefetchActiveNodeRef = useRef(null);

	const [params] = useSearchParams();

	function calcDeps() {
		const depObj = {};

		for (let id of Object.keys(defaultGraph.edges)) {
			const edge = defaultGraph.edges[id];

			const dependenceis = [...Object.keys(edge).map((k) => edge[k].tail), id];
			let toCheck = Object.keys(edge).map((k) => edge[k].tail);

			while (toCheck.length) {
				const found = [];
				for (let key of toCheck) {
					const checkingEdge = defaultGraph.edges[key];
					for (let id of Object.keys(checkingEdge).map((k) => checkingEdge[k].tail)) {
						if (!dependenceis.includes(id)) {
							found.push(id);
							dependenceis.push(id);
						}
					}
				}
				toCheck = found;
			}
			depObj[id] = dependenceis;
		}

		setEdgeDeps(depObj);
	}

	useEffect(() => {
		calcDeps();
	}, [defaultGraph]);

	useEffect(() => {
		const node_id = params.get("a");
		if (graph && node_id && !prefetchActiveNodeRef.current) {
			prefetchActiveNodeRef.current = true;
			setActiveNode(graph.nodes[node_id]);
		}
	}, [graph, params]);

	return {
		graph,
		setGraph,
		x,
		setX,
		y,
		setY,
		scale,
		setScale,
		nodePositions,
		setNodePositions,
		edgeDeps,
		setEdgeDeps,
		activeNode,
		setActiveNode,
        activeFilters,
        setActiveFilters,
        selectedDate,
        setSelectedDate
	};
}

export interface IExtendedGlintState extends IGlintState {
	reportNodePosition: (id: string, x: number, y: number) => void;
	getNodePosition: (id: string) => { x: number; y: number };
}

export function useGlintState(): IExtendedGlintState {
	const glintState = useContext(GlintContext);

	function reportNodePosition(id: string, x: number, y: number) {
		glintState.setNodePositions((p) => ({ ...p, [id]: { x, y } }));
	}

	function getNodePosition(id: string) {
		return glintState.nodePositions[id];
	}

    function shiftNodes() {
        if(glintState.activeNode) {
            let min_x = Infinity;
            let min_y = Infinity;
            let max_x = 0;
            let max_y = 0;

            for(const node_id of glintState.edgeDeps[glintState.activeNode.id]){
                const {x, y} = getNodePosition(node_id);

                if(x > max_x) {
                    max_x = x + (8*16);
                }
                if(x < min_x) {
                    min_x = x;
                }
                if(y > max_y) {
                    max_y = y;
                }
                if(y < min_y) {
                    min_y = y;
                }
            }

            if((max_x - min_x) > 0) {
                const width = document.body.clientWidth;
                const padding = (width - (max_x - min_x)) / 2;
                const xOffset = padding - min_x - 32
                const yOffset = (-min_y) + 42

                animate(glintState.x, xOffset, 100, glintState.setX)
                animate(glintState.y, yOffset, 100, glintState.setY)
            }
        }
    }

    useEffect(() => {
        shiftNodes()
    }, [glintState.activeNode])

	return {
		...glintState,
		reportNodePosition,
		getNodePosition
	};
}
