import { RedshiftData } from "@aws-sdk/client-redshift-data";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { Aggregation } from "@glint/types";
import { useAuthState } from "@glint/state";

const DATABASE_NAME = "traceability_database";
const WORKGROUP_NAME = "traceability-framework-gamma-wg";

export function useRedshift() {
	const { getAWSCreds } = useAuthState();
	const rs = new RedshiftData(getAWSCreds());

	function getDailyDocumentCounts(table: string, date_col = "acquired", sum_col = "documents", aggregation = Aggregation.Sum, where=null) {
		return query(
			`select ${date_col}, ${aggregation}(${sum_col}) as ${sum_col} from ${table} ${where ? where : ""} group by ${date_col} order by ${date_col};`
		);
	}

	async function query(sql: string) {
		const res = await rs.executeStatement({
			Database: "dev",
			ClusterIdentifier: "llm-data-drops",
			SecretArn: "arn:aws:secretsmanager:us-east-1:780910371930:secret:glint/redshift-access-5nZANv",
			Sql: sql
		});
		while (true) {
			const resp = await rs.describeStatement({ Id: res.Id });
			if (resp.Error) {
				throw new Error(resp.Error);
			}
			if (resp.HasResultSet) {
				const result = await rs.getStatementResult({ Id: res.Id });

				return unmarshall(result);
			}
		}
	}

	return {
		rs,
		getDailyDocumentCounts
	};
}

function unmarshall(data) {
	const columns = data.ColumnMetadata;
	const rows = data.Records;

	return rows.map((row) => {
		const newRow = {};
		row.forEach((row, idx) => {
			if (row.isNull) {
				newRow[columns[idx].name] = null;
			} else {
				newRow[columns[idx].name] = row[Object.keys(row)[0]];
			}
		});
		return newRow;
	});
}
